<template>
  <div>
    <browse></browse>
  </div>
</template>

<script>
import browse from './../components/browse';
export default {
  name: 'sls-home',
  components: { browse },
  head: {
    title: {
      inner: 'Slay The Spire Tier List and Deck Builder | SpireSpy',
    },
    meta: [
      { name: 'description', content: 'Slay The Spire card and relic information and tier lists. View card and relic synergies and antisynergies. See suggested draft choices in real time with our deck builder.' },

      // Google
      { itemprop: 'name', content: 'SpireSpy' },
      { itemprop: 'description', content: 'Slay The Spire tier list and deck builder' },
      { itemprop: 'image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },

      // Facebook
      { property: 'og:title', content: 'SpireSpy' },
      { property: 'og:description', content: 'Slay The Spire tier list and deck builder' },
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: 'SpireSpy' },
      { property: 'og:url', content: 'https://maybelatergames.co.uk/tools/slaythespire/' },
      { property: 'og:image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },
      // { property: 'fb:app_id', content: '123456789' },
      // { property: 'og:locale', content: 'en_US' },
      // { property: 'article:author', content: '' },

      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'SpireSpy' },
      { name: 'twitter:description', content: 'Slay The Spire tier list and deck builder' },
      { name: 'twitter:image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },
    ],
    link: [
      { rel: 'canonical', href: 'https://maybelatergames.co.uk/spirespy/', id: 'canonical' },
    ]
  },

};
</script>
